import i18n from '@/i18n'
import SelectOption from '@/shared/classes/form/select-option'

enum Categories {
  graphicDesign = 'graphic-design',
  photoshop = 'photoshop',
  industrialDesign = 'industrial-design',
  companyImage = 'company-image',
  painting = 'painting',
  photography = 'photography',
  logoDesign = 'logo-design',
  uxUiDesign = 'ux-ui-design',
  advertising = 'advertising',
  figma = 'figma',
  productDesign = 'product-design',
  other = 'other',
}

export const PortfolioCategories = [
  new SelectOption().setKey(Categories.graphicDesign).setTitle(String(i18n.t('Graphic Design'))),
  new SelectOption().setKey(Categories.photoshop).setTitle(String(i18n.t('Photoshop'))),
  new SelectOption().setKey(Categories.industrialDesign).setTitle(String(i18n.t('Industrial Design'))),
  new SelectOption().setKey(Categories.companyImage).setTitle(String(i18n.t('Company Image'))),
  new SelectOption().setKey(Categories.painting).setTitle(String(i18n.t('Painting'))),
  new SelectOption().setKey(Categories.photography).setTitle(String(i18n.t('Photography'))),
  new SelectOption().setKey(Categories.logoDesign).setTitle(String(i18n.t('Logo Design'))),
  new SelectOption().setKey(Categories.uxUiDesign).setTitle(String(i18n.t('UX/UI Design'))),
  new SelectOption().setKey(Categories.advertising).setTitle(String(i18n.t('Advertising'))),
  new SelectOption().setKey(Categories.figma).setTitle(String(i18n.t('Figma'))),
  new SelectOption().setKey(Categories.productDesign).setTitle(String(i18n.t('Product Design'))),
]
