import { AxiosResponse } from 'axios'
import http from '../http'
import UserBanner from '../interfaces/models/user/user-banner'

export default class DesignerService {
  static async updateAboutMe(data: any): Promise<AxiosResponse> {
    return http.put('update-about-me', data)
  }

  static async updateAvailability(data: any): Promise<AxiosResponse> {
    return http.put('update-availability', data)
  }

  static async getBanner(): Promise<UserBanner> {
    return http.get('user-details/designer-banner').then((response: AxiosResponse) => response.data)
  }

  static async uploadBanner(file: File): Promise<UserBanner> {
    const formData = new FormData()
    formData.append('image', file)

    return http
      .post('user-details/upload-banner', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse<UserBanner>) => response.data)
  }

  static async getDesigner(uuid: string): Promise<void> {
    return http.get(`users/designers/${uuid}`).then((response: AxiosResponse) => response.data)
  }

  static async checkSlug(slug: string): Promise<AxiosResponse> {
    return http.put('check-slug', { slug }).then((response: AxiosResponse) => response.data)
  }
}
